// core
import React from 'react'
import Router from 'next/router'
import App from 'next/app'
import Head from 'next/head'

// 3rd party
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import * as Sentry from '@sentry/node'

// local
import 'styles/scss/styles.scss'
import { logger } from '../core'
import { theme } from 'styles'
import { SettingsProvider } from 'context'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
})

class AccountsApp extends App<any> {
  constructor(args) {
    super(args)
  }

  state = {
    clientDetails: null,
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error)
    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo)
  }

  async componentDidMount() {
    logger.info('route: ' + Router.route)
    logger.info(Router.query)
    // Remove the server-side injected CSS.
    const jssStyles: any = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  render() {
    const { Component, pageProps, err, query } = this.props
    logger.info(query)

    return (
      <>
        <Head>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        </Head>
        <SettingsProvider settings={null}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Component {...pageProps} err={err} />
          </ThemeProvider>
        </SettingsProvider>
      </>
    )
  }
}

export default AccountsApp
